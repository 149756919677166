import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled, ThemeProvider } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useLocation, useNavigate } from "react-router-dom";
import { Printer, Text, render } from "react-thermal-printer";
import Sockette from "sockette";
import CircularProgress from "@mui/material/CircularProgress";

import styles from "./OrderSummary.module.css";

import { connect } from "react-redux";
import {
  updateActivePrinter,
  updateDrawerOpen,
  updateRestaurantCourseList,
  updateRestaurantMenuList,
  updateTableOrderBillingList,
  updateTableOrderList,
} from "../../../redux/actions/userDataActions";

import * as Api from "../../../api";

import { ReactComponent as Cash } from "../../../assets/images/cash.svg";
import { ReactComponent as Ecash } from "../../../assets/images/ecash.svg";
import { ReactComponent as CreditCard } from "../../../assets/images/creditcard.svg";
import { ReactComponent as GiftCard } from "../../../assets/images/giftcard.svg";
import { ReactComponent as Invoice } from "../../../assets/images/invoice.svg";
import { ReactComponent as PendingAmount } from "../../../assets/images/pendingamount.svg";
import { ReactComponent as AddTip } from "../../../assets/images/addtip.svg";
import { ReactComponent as Change } from "../../../assets/images/change.svg";
import { ReactComponent as PrintReceipt } from "../../../assets/images/printreceipt.svg";
import { ReactComponent as PaidIcon } from "../../../assets/images/paid.svg";
import { ReactComponent as BackBtnIcon } from "../../../assets/images/back2.svg";
import { ReactComponent as FileIcon } from "../../../assets/images/file2.svg";
import { ReactComponent as DownIcon } from "../../../assets/images/arrow_down.svg";
import { ReactComponent as UpIcon } from "../../../assets/images/arrow_up.svg";
import { ReactComponent as MoneyIcon } from "../../../assets/images/money.svg";
import { ReactComponent as AlertIcon } from "../../../assets/images/alert.svg";

import Container from "../../../components/Container";
import Loader from "../../../components/Loader";
import OrderSummaryDiscountSplit from "../../../components/OrderSummaryDiscountSplit";
import OrderSummaryCartItem from "../../../components/OrderSummaryCartItem";
import AlertMsg from "../../../components/AlertMsg";
import OrderSummaryPaymentAlert from "../../../components/OrderSummaryPaymentAlert";
import SelectActivePrinterModal from "../../../components/SelectActivePrinterModal";
import DiningNavbar from "../../../components/DiningNavbar";
import Calculator from "../../../components/Calculator";
import ConfirmAlert from "../../../components/ConfirmAlert";

import { THEME_MODE } from "../../../constants/Theme";
import {
  CURRENCY_SYMBOL,
  DINING_DETAIL_BTN_HEIGHT,
  DINING_DETAIL_CONTENT_HEIGHT,
  DISCOUNT_TYPE,
  DISCOUNT_VALUE_TYPE,
  IS_LARGE_SCREEN,
  RESTAURANT_ADMIN_ROLE_ID,
  RESTAURANT_USER_ROLE_ID,
  SPLIT_PAYMENT_METHOD,
  SPLIT_PAYMENT_TYPE,
  SPLIT_TYPE,
} from "../../../constants";
import {
  getCartItemsTotalPrice,
  getOrdersTotalPrice,
  onSetCartModifierData,
} from "../../../constants/CommonFunc";

const MuiSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: IS_LARGE_SCREEN ? 88 : 62,
  height: IS_LARGE_SCREEN ? 58 : 42,
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      transform: IS_LARGE_SCREEN ? "translateX(32px)" : "translateX(20px)",
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: IS_LARGE_SCREEN ? 30 : 15,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: IS_LARGE_SCREEN ? 16 : 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: IS_LARGE_SCREEN ? 16 : 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: IS_LARGE_SCREEN ? 30 : 20,
    height: IS_LARGE_SCREEN ? 30 : 20,
    margin: IS_LARGE_SCREEN ? 4 : 1.8,
  },
}));

let BTN_HEIGHT = IS_LARGE_SCREEN ? 100 : 70;
let HEADER_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT;
let LEFT_CONTENT_HEIGHT = window.innerHeight - 12;
let GAP_VALUE = IS_LARGE_SCREEN ? 8 : 5;
let CONTENT_HEIGHT =
  DINING_DETAIL_CONTENT_HEIGHT - DINING_DETAIL_BTN_HEIGHT - 5;

let allItems = "all_item";
let selectItem = "select_item";
let splitByItem = "split_by_item";
let splitByAmt = "split_by_amount";
let splitEqual = "split_equal";
let splitByTwo = "split_by_2";

let ICON_SIZE = IS_LARGE_SCREEN ? 40 : 20;
let ICON_SIZE_2 = IS_LARGE_SCREEN ? 12 : 8;

let CALC_PADDING = IS_LARGE_SCREEN ? 20 : 15;

let PAYMENT_METHOD = [
  {
    name: "CASH",
    type: "cash",
    icon: <Cash width={ICON_SIZE + 5} height={ICON_SIZE + 5} />,
  },
  {
    name: "CARDS",
    type: "card",
    icon: <CreditCard width={ICON_SIZE + 5} height={ICON_SIZE + 5} />,
  },
];

let OPTIONS_LIST = [
  {
    name: "Invoice",
    type: "invoice",
    icon: <Invoice width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    name: "E-Cash",
    type: "ecash",
    icon: <Ecash width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    name: "Gift Cards",
    type: "giftcard",
    icon: <GiftCard width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    name: "Service charge",
    type: "servicecharge",
    icon: <MoneyIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
];

const PaymentMethod = (props) => {
  let height = props.tab == "option" ? 55 : 60;
  return (
    <Box
      pl={2}
      pr={1}
      py={1.5}
      mb={`${GAP_VALUE}px`}
      onClick={props.onClick}
      className={styles.paymentMethodView}
      style={{
        width: props.width,
        // height: CONTENT_HEIGHT / 5 - (IS_LARGE_SCREEN ? 70 : 50),
        height: IS_LARGE_SCREEN ? height + 20 : height,
        cursor: "pointer",
        backgroundColor: props.active
          ? props.themeMode.theme
          : props.themeMode.headerBg2,
      }}
      display={"flex"}
      alignItems={"center"}
    >
      <Box sx={{ path: { stroke: props.iconColor }, lineHeight: 0 }}>
        {props.icon}
      </Box>
      <Typography
        pl={1}
        className={styles.paymentMethodsName}
        style={{
          fontFamily: props.tab == "option" ? "InterSemiBold" : "InterBold",
          fontSize: props.tab == "option" ? "16px" : "20px",
          color: props.active
            ? props.themeMode.text
            : props.themeMode.lightText,
        }}
      >
        {props.type}
      </Typography>
    </Box>
  );
};

const Dining = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [tableId, setTableId] = useState(location.pathname.split("/").pop());
  const [cartItems, setCartItems] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [orderData, setOrderData] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentOption, setPaymentOption] = useState("");
  const [discountSplitRemove, setDiscountSplitRemove] = useState("");
  const [calcValue, setCalcValue] = useState(0);

  const [totalPrice, setTotalPrice] = useState(0);
  const [discountAmt, setDiscountAmt] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [taxVal, setTaxVal] = useState(0);
  const [tipValue, setTipValue] = useState(0);
  const [changeValue, setChangeValue] = useState(0);
  const [coversVal, setCoversVal] = useState(0);
  const [coversOriginalVal, setCoversOriginalVal] = useState(0);

  const [allOrSelectItem, setAllOrSelectItem] = useState(allItems);
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);

  const [pendingAmtList, setPendingAmtList] = useState([]);
  const [selectedPendingAmt, setSelectedPendingAmt] = useState(null);

  const [splitType, setSplitType] = useState("");
  const [splitItems, setSplitItems] = useState([]);

  const [selectedDiscountItems, setSelectedDiscountItems] = useState([]);
  const [discountType, setDiscountType] = useState("");
  const [discountItems, setDiscountItems] = useState([]);

  const [showPaymentAlert, setShowPaymentAlert] = useState(false);
  const [printReceipt, setPrintReceipt] = useState(false);
  const [activePrintModalVisible, setActivePrintModalVisible] = useState(false);
  const [printerConnectLoading, setPrinterConnectLoading] = useState(false);
  const [payCalcGridWidth, setPayCalcGridWidth] = useState(100);
  const [payGridHeight, setPayGridHeight] = useState(100);
  const [isPendingPaymentCompleted, setIsPendingPaymentCompleted] =
    useState(false);
  const [paymentMethodOptionGridHeight, setPaymentMethodOptionGridHeight] =
    useState(100);
  const [confirmAlertVisible, setConfirmAlertVisible] = useState(false);
  const [pendingAmtBtnDisable, setPendingAmtBtnDisable] = useState(false);
  const [currencyVal, setCurrencyVal] = useState(
    props.restaurantDetail?.currecnyIcon
      ? props.restaurantDetail?.currecnyIcon
      : ""
  );
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  let tabBg = { true: theme_mode.theme, false: theme_mode.diningCartHeader };
  let tabText = { true: theme_mode.text, false: theme_mode.lightText };

  const printerRef = useRef(null);

  useEffect(() => {
    if (props.drawerOpen) {
      props.updateDrawerOpen(false);
    }
  }, []);

  useEffect(() => {
    let div = document.getElementById("orderSummaryPayCalcGrid");
    if (div) {
      setPayCalcGridWidth(div.clientWidth);
    }
    let div2 = document.getElementById("orderSummaryPayGrid");
    if (div2) {
      setPayGridHeight(div2.clientHeight);
    }
    let div3 = document.getElementById("paymentMethodOptionGrid");
    if (div3) {
      setPaymentMethodOptionGridHeight(div3.clientHeight);
    }
  }, [
    props.drawerOpen,
    isLoading,
    discountSplitRemove,
    discountAmt,
    pendingAmtList,
  ]);

  useEffect(() => {
    let discount_amt = 0;
    let subTotalAmt = 0;
    let cart_items_list = [];
    cartItems.map((item) => {
      let amt = getCartItemsTotalPrice(item, []);
      subTotalAmt += amt;

      let discountTotal = 0;
      if (discountType == allItems) {
        if (discountItems.length) {
          if (
            discountItems[0].discountValueType ==
            DISCOUNT_VALUE_TYPE["percentage"]
          ) {
            discountTotal = amt * (parseFloat(discountItems[0].value) / 100);
            discount_amt = discount_amt + discountTotal;
          }
          if (
            discountItems[0].discountValueType == DISCOUNT_VALUE_TYPE["amount"]
          ) {
            discountTotal = parseFloat(
              discountItems[0].value / cartItems.length
            );
            discount_amt = discount_amt + discountTotal;
          }
        }
      } else if (discountType == selectItem) {
        if (discountItems.length) {
          let list = [...discountItems];
          let findIndex = list.findIndex((y) => y?._idMenuItem == item._id);
          if (findIndex >= 0) {
            if (list[findIndex]?.value) {
              let discountData = list[findIndex];
              if (
                discountData?.discountValueType ==
                DISCOUNT_VALUE_TYPE["percentage"]
              ) {
                discountTotal = amt * (parseFloat(discountData?.value) / 100);
                discount_amt = discount_amt + discountTotal;
              }
              if (
                discountData?.discountValueType == DISCOUNT_VALUE_TYPE["amount"]
              ) {
                discountTotal = parseFloat(discountData?.value);
                discount_amt = discount_amt + discountTotal;
              }
            }
          }
        }
      }

      cart_items_list.push({ ...item, totalPrice: amt - discountTotal });
    });

    setDiscountAmt(discount_amt.toFixed(2));
    setSubTotal(subTotalAmt.toFixed(2));
    let total_val = subTotalAmt + taxVal - discount_amt;
    setTotalPrice(total_val.toFixed(2));

    let data = {
      _id: 1,
      paidAmount: total_val,
      paymentMethod: null,
      tip: tipValue,
      cashAmount: total_val,
      change: changeValue,
      paymentSpecificData: {},
      _idMenuItem: [],
      paid: false,
      splitItem: false,
    };
    let payment_data = [];
    if (orderData) {
      let list1 = [...props.tableOrderBillingList];
      let fIndex1 = list1.findIndex((a) => a.orderId == orderData._id);
      if (fIndex1 >= 0) {
        if (list1[fIndex1]?.["splitPayment"]?.["splitPayment"]) {
          payment_data = list1[fIndex1]?.["splitPayment"]?.["splitPayment"];
        }
      }
    }

    setPendingAmtBtnDisable(false);
    let split_payment_list = [data];
    if (splitType == splitEqual) {
      let covers_val = payment_data.length
        ? payment_data.length
        : coversVal && coversVal > 0
        ? coversVal
        : 1;
      let splitAmt = total_val / covers_val;
      let list = [];
      Array.from({ length: covers_val }, (_, i) => i + 1).map((x, index) => {
        let paid_data = null;
        let paid_index = payment_data.findIndex((y) => y._id == index + 1);
        if (paid_index >= 0) {
          paid_data = payment_data[paid_index];
        } else {
          paid_data = {
            ...data,
            _id: index + 1,
            paidAmount: splitAmt,
            cashAmount: splitAmt,
          };
        }
        list.push(paid_data);
      });
      split_payment_list = list;
    } else if (splitType == "split_by_2") {
      let splitAmt = total_val / 2;
      let paid_data = null;
      let paid_index = payment_data.findIndex((y) => y._id == 1);
      if (paid_index >= 0) {
        paid_data = payment_data[paid_index];
      } else {
        paid_data = {
          ...data,
          paidAmount: splitAmt,
          cashAmount: splitAmt,
        };
      }
      split_payment_list = [
        {
          ...paid_data,
          _id: 1,
          paid:
            payment_data.findIndex((y) => y._id == 1 && y.paid == true) >= 0,
        },
        {
          ...paid_data,
          _id: 2,
          paid:
            payment_data.findIndex((y) => y._id == 2 && y.paid == true) >= 0,
        },
      ];
    } else if (splitType == "split_by_item") {
      let split_items = [];
      let split_menu_item_list = [];

      splitItems.map((item) => {
        let split_paid_amt = 0;
        let split_menu_items = [];

        cart_items_list
          .filter((z) => item.includes(z._idMenuItem))
          .map((x) => {
            split_menu_items.push(x._idMenuItem);
            split_paid_amt =
              split_paid_amt + (x?.totalPrice ? x.totalPrice : 0);
          });

        split_menu_item_list = [...split_menu_item_list, ...split_menu_items];

        let paid_data = null;
        let paid_index = payment_data.findIndex(
          (y) => y._id == split_items.length + 1
        );
        if (paid_index >= 0) {
          paid_data = payment_data[paid_index];
        } else {
          paid_data = {
            ...data,
            _id: split_items.length + 1,
            paidAmount: split_paid_amt,
            cashAmount: split_paid_amt,
            _idMenuItem: split_menu_items,
            splitItem: true,
          };
        }

        split_items.push(paid_data);
      });

      let paid_amt = 0;
      let menu_items = [];

      cart_items_list
        .filter((z) => !split_menu_item_list.includes(z._idMenuItem))
        .map((x) => {
          menu_items.push(x._idMenuItem);
          paid_amt = paid_amt + (x?.totalPrice ? x.totalPrice : 0);
        });

      if (menu_items.length) {
        let paid_data = null;
        let paid_index = payment_data.findIndex(
          (y) => y._id == split_items.length + 1
        );
        if (paid_index >= 0) {
          paid_data = payment_data[paid_index];
        } else {
          paid_data = {
            ...data,
            _id: split_items.length + 1,
            paidAmount: paid_amt,
            cashAmount: paid_amt,
            _idMenuItem: menu_items,
          };
        }
        split_items.push(paid_data);
      }
      split_payment_list = split_items;
    } else {
      if (payment_data.length > 1) {
        setPendingAmtBtnDisable(true);
        split_payment_list = payment_data;
      }
    }
    if (split_payment_list.length) {
      let not_paid_list = split_payment_list.filter((y) => y.paid == false);
      if (not_paid_list.length) {
        setSelectedPendingAmt(not_paid_list[0]);
      }
    }
    setPendingAmtList(split_payment_list);
  }, [
    cartItems,
    coversVal,
    splitType,
    splitItems,
    discountItems,
    discountType,
    orderData,
  ]);

  useEffect(() => {
    if (orderData) {
      let list = [...props.tableOrderBillingList];
      let fIndex = list.findIndex((a) => a.orderId == orderData._id);
      if (fIndex >= 0) {
        let data = list[fIndex];
        let split_items = [];
        data.splitPayment.splitPayment.map((y) => {
          if (y?.splitItem == true) {
            split_items.push(y._idMenuItem);
          }
        });
        setSplitItems(split_items);
        if (data.splitPayment?.splitType) {
          let split_val = getKeyByValue(
            SPLIT_TYPE,
            data.splitPayment.splitType
          );
          if (split_val == splitByAmt) {
            setSplitType("");
          } else {
            setSplitType(
              getKeyByValue(SPLIT_TYPE, data.splitPayment.splitType)
            );
          }
        }
        setDiscountItems(data.discount.discounts);
        if (data.discount?.discountType) {
          setDiscountType(
            getKeyByValue(DISCOUNT_TYPE, data.discount.discountType)
          );
        }
      }
    }
  }, [props.tableOrderBillingList, orderData]);

  const getKeyByValue = (obj, value) => {
    return Object.keys(obj).find((key) => obj[key] === value);
  };

  useEffect(() => {
    if (props.restaurantMenuList.length) {
      let data = JSON.parse(JSON.stringify(props.restaurantMenuList));
      setMenuList(data);
      getCourseList();
    } else {
      getMenuList();
    }
  }, []);

  useEffect(() => {
    if (menuList.length > 0 && courseList.length > 0) {
      getOrdersWithTableId();
    }
  }, [courseList, menuList]);

  useEffect(() => {
    if (discountSplitRemove == "discount") {
      if (selectedMenuItems.length) {
        if (typeof selectedMenuItems[0].value != "undefined") {
          let list = [...selectedDiscountItems];
          let findIndex = list.findIndex(
            (x) => x?._idMenuItem == selectedMenuItems[0]._idMenuItem
          );
          if (findIndex >= 0) {
            if (selectedMenuItems[0].value == 0) {
              list = list.filter(
                (x) => x?._idMenuItem != selectedMenuItems[0]._idMenuItem
              );
            } else {
              list[findIndex] = selectedMenuItems[0];
            }
          } else {
            if (selectedMenuItems[0]?.value != 0) {
              list.push(selectedMenuItems[0]);
            }
          }
          setSelectedDiscountItems(list);
          onApplyDiscount(allOrSelectItem, 0, false, list);
        }
      }
    }
  }, [selectedMenuItems]);

  useEffect(() => {
    if (pendingAmtList.length) {
      let pending_amt = pendingAmtList.filter((x) => x?.paid == false);
      if (pending_amt.length) {
        setSelectedPendingAmt(pending_amt[0]);
      }
    }
    setIsPendingPaymentCompleted(
      pendingAmtList.filter((x) => x?.paid == false).length == 0
    );
  }, [pendingAmtList]);

  const getMenuList = () => {
    let filter = "?pageSize=300000&pageNum=1&orderBy=order&orderByDir=asc";
    Api.getMenuList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setMenuList(data);
        props.updateRestaurantMenuList(data);
        getCourseList();
      } else {
        setIsLoading(false);
      }
    });
  };

  const getCourseList = () => {
    let courses = {
      _idRestaurantCategory: [],
      _id: null,
      name: "Miscellaneous",
      _idRestaurant: props.restaurantId,
    };
    if (props.restaurantCourseList.length) {
      setCourseList(
        JSON.parse(JSON.stringify([...props.restaurantCourseList, courses]))
      );
    } else {
      setIsLoading(true);
      let filter = "/all";
      Api.getCourseList(props.restaurantId, filter).then((response) => {
        if (response.success) {
          const data = response.data.rows.sort((a, b) => a?.order - b?.order);
          props.updateRestaurantCourseList(data);
          setCourseList(JSON.parse(JSON.stringify([...data, courses])));
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      });
    }
  };

  const getOrdersWithTableId = () => {
    setIsLoading(true);
    let rows = props.tableOrderList.filter((x) =>
      (x._idRestaurantTable?.length ? x._idRestaurantTable : []).includes(
        tableId
      )
    );
    if (rows.length > 0) {
      let data = rows[0];
      let currency_val = "";
      setOrderData(data);
      let cover_val = data?.userDetails?.covers ? data.userDetails.covers : 0;
      setCoversVal(cover_val);
      setCoversOriginalVal(cover_val);

      let cart_list = [];
      data.cart.map((y) => {
        let findIndex = menuList.findIndex((z) => z._id == y._idMenuItem);
        if (findIndex >= 0) {
          if (
            currency_val == "" &&
            (currencyVal == "" || currencyVal == null)
          ) {
            currency_val = y.price.currency;
          }

          cart_list.push({
            ...y,
            ...menuList[findIndex],
            name: y?.menuItemName
              ? y.menuItemName
              : menuList[findIndex]["name"],
            price: y.price,
            _idRestaurantCourse: y?._idRestaurantCourse
              ? y._idRestaurantCourse
              : null,
            restaurantCourseName: y.restaurantCourseName,
            cartModifier: onSetCartModifierData(y.modifiers ?? [], menuList),
          });
        }
      });
      if (currency_val != "") {
        setCurrencyVal(currency_val);
      }
      setCartItems(cart_list);
    }
    setIsLoading(false);
  };

  const onSelectTab = (tab) => {
    setCalcValue(0);
    setPaymentMethod("");
    if (tab == discountSplitRemove) {
      setSelectedMenuItems([]);
      setDiscountSplitRemove("");
      return;
    }
    if (tab == "discount") {
      setAllOrSelectItem(allItems);
      setSelectedDiscountItems(JSON.parse(JSON.stringify(discountItems)));
    } else if (tab == "split") {
      setAllOrSelectItem(splitEqual);
    } else {
      // if (discountSplitRemove == "discount") {
      //   setDiscountItems([]);
      //   setDiscountType("");
      //   setSelectedDiscountItems([]);
      // } else if (discountSplitRemove == "split") {
      //   setSplitType("");
      //   setSplitItems([]);
      //   setCoversVal(coversOriginalVal);
      // }
      // setSelectedMenuItems([]);
      //
      // props.updateTableOrderBillingList(
      //   [...props.tableOrderBillingList].filter(
      //     (a) => a.orderId != orderData._id
      //   )
      // );
    }
    setSelectedMenuItems([]);
    setDiscountSplitRemove(tab);
  };

  const onSelectMenu = (item) => {
    setCalcValue(0);
    setPaymentMethod("");
    if (discountSplitRemove == "discount") {
      if (allOrSelectItem == allItems) {
      } else {
        setSelectedMenuItems([{ _idMenuItem: item._id }]);
      }
    } else {
      if (
        (discountSplitRemove == "split" && allOrSelectItem == splitByItem) ||
        discountSplitRemove == "remove"
      ) {
        let list = [...selectedMenuItems];
        let findIndex = list.findIndex((x) => x._idMenuItem == item._id);
        let menu_items = [];
        if (findIndex >= 0) {
          menu_items = list.filter((x) => x._idMenuItem != item._id);
        } else {
          menu_items = [...list, { _idMenuItem: item._id }];
        }
        setSelectedMenuItems(menu_items);
      }
    }
  };

  const onChangeDiscountValue = (type, val) => {
    if (allOrSelectItem == allItems) {
      let items = [];
      cartItems.map((x) => {
        items.push({
          _idMenuItem: x._idMenuItem,
          discountValueType: DISCOUNT_VALUE_TYPE[type],
          value: val / cartItems.length,
        });
      });
      setSelectedMenuItems(items);
    } else {
      if (selectedMenuItems.length) {
        setSelectedMenuItems([
          {
            _idMenuItem: selectedMenuItems[0]._idMenuItem,
            discountValueType: DISCOUNT_VALUE_TYPE[type],
            value: val,
          },
        ]);
      }
    }
  };

  const onApplyDiscount = (type, val, close, list = selectedDiscountItems) => {
    if (allOrSelectItem == allItems) {
      if (val != 0) {
        setDiscountItems([
          {
            discountValueType: DISCOUNT_VALUE_TYPE[type],
            value: val,
            // _idMenuItem: "",
          },
        ]);
      } else {
        setDiscountItems([]);
      }
      setSelectedDiscountItems([]);
    } else {
      setDiscountItems(list);
    }
    setDiscountType(allOrSelectItem);
    props.updateTableOrderBillingList(
      [...props.tableOrderBillingList].filter((a) => a.orderId != orderData._id)
    );
    if (close) {
      setSelectedDiscountItems([]);
      setSelectedMenuItems([]);
      setDiscountSplitRemove("");
    }
  };

  const onApplySplit = (type, covers, close) => {
    if (type == splitByItem) {
      setSplitType("");
      if (selectedMenuItems.length) {
        setSplitType(type);
        let data = selectedMenuItems.map((x) => x._idMenuItem);
        setSplitItems([...splitItems, data]);
      }
    } else {
      setSplitType(type);
      setCoversVal(covers);
      setSplitItems([]);
    }
    props.updateTableOrderBillingList(
      [...props.tableOrderBillingList].filter((a) => a.orderId != orderData._id)
    );
    if (close) {
      setSelectedMenuItems([]);
      setDiscountSplitRemove("");
    }
  };

  const onCancelDiscountSplit = () => {
    setAllOrSelectItem(allItems);
    setSelectedMenuItems([]);
    setSelectedDiscountItems([]);
    setDiscountSplitRemove("");
    setCalcValue(0);
  };

  const onSetAllOrSelectItem = (val) => {
    setCalcValue(0);
    setAllOrSelectItem(val);
    if (discountSplitRemove == "discount") {
      if (val == allItems) {
        setSelectedMenuItems([]);
        setSelectedDiscountItems([]);
      } else {
        setSelectedMenuItems([]);
      }
    } else {
      if (val != splitByItem) {
        setSelectedMenuItems([]);
      }
    }
  };

  const onSelectPaymentMethod = (method) => {
    setCalcValue(0);
    if (pendingAmtList.length == 1) {
      setPaymentMethod(method);
    } else {
      onCreateOrder(method, false);
    }
  };

  const onSelectPaymentOptions = (option) => {
    setPaymentOption(option);
  };

  const onSelectPendingAmtList = (data) => {
    setSelectedPendingAmt(data);
  };

  const deleteOrderItem = (data1, cart_items) => {
    if (orderData == null) {
      setMsgAlert({
        open: true,
        message: "Orders not found",
        msgType: "error",
      });
      return;
    }
    let datas = JSON.stringify(data1);
    Api.updateOrder(datas, orderData._id).then((response) => {
      if (response.success) {
        setSelectedMenuItems([]);
        setDiscountSplitRemove("");
        setCartItems(cart_items);
        setDiscountItems(
          discountItems.filter(
            (z) =>
              cart_items.map((x) => x._idMenuItem).includes(z?._idMenuItem) ||
              z?._idMenuItem == "" ||
              typeof z._idMenuItem == "undefined"
          )
        );

        let split_items = [];
        splitItems.map((x) => {
          let items1 = [];
          x.map((y) => {
            if (cart_items.map((x) => x._idMenuItem).includes(y)) {
              items1.push(y);
            }
          });
          if (items1.length) {
            split_items.push(items1);
          }
        });
        setSplitItems(split_items);
        let list1 = [...props.tableOrderList];
        let filter_index = list1.findIndex((x) => x._id == orderData._id);
        if (filter_index >= 0) {
          list1[filter_index] = { ...orderData, ...data1 };
          props.updateTableOrderList(list1);
        }
        props.updateTableOrderBillingList(
          [...props.tableOrderBillingList].filter(
            (a) => a.orderId != orderData._id
          )
        );
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const onCreateOrder = (pay_method = paymentMethod, close_table) => {
    let selected_pending_amt = close_table ? "" : selectedPendingAmt;
    let covers_val = coversOriginalVal;
    if (splitType == splitByTwo) {
      covers_val = 2;
    }
    if (splitType == splitEqual) {
      covers_val = pendingAmtList.length;
    }
    if (orderData == null) {
      setMsgAlert({
        open: true,
        message: "Orders not found",
        msgType: "error",
      });
      return;
    }
    if (selected_pending_amt == null && !close_table) {
      setMsgAlert({
        open: true,
        message: "Select Pending Amount",
        msgType: "error",
      });
      return;
    }

    let payment_data = {};
    let discount_data = {};
    if (discountType != "") {
      discount_data = {
        totalDiscount: Number(discountAmt),
        discountType: DISCOUNT_TYPE[discountType],
        discounts: discountItems,
      };
    } else {
      discount_data = {
        totalDiscount: 0,
        discountType: null,
        discounts: [],
      };
    }

    if (pendingAmtList.length > 1) {
      let split_payment = [];
      let original_split_payment = [];
      JSON.parse(JSON.stringify(pendingAmtList)).map((x) => {
        let cashAmt = x.paidAmount + tipValue + changeValue;
        let data2 = {};
        if (selected_pending_amt._id == x._id) {
          x.paid = true;
          data2 = {
            paymentMethod: SPLIT_PAYMENT_METHOD[pay_method],
            tip: tipValue,
            cashAmount: Number(cashAmt.toFixed(2)),
            change: changeValue,
            paidAmount: Number(x.paidAmount.toFixed(2)),
            _idMenuItem: x._idMenuItem,
            paymentSpecificData: {},
          };
          split_payment.push({
            ...x,
            ...data2,
            paid: true,
          });
        } else {
          data2 = {
            paymentMethod: x.paymentMethod,
            tip: x.tip,
            cashAmount: Number(x.cashAmount.toFixed(2)),
            change: x.change,
            paidAmount: Number(x.paidAmount.toFixed(2)),
            _idMenuItem: x._idMenuItem,
            paymentSpecificData: x.paymentSpecificData,
          };
          split_payment.push(x);
        }
        if (x.paid == true) {
          original_split_payment.push(data2);
        }
      });
      setPendingAmtList(split_payment);
      payment_data = {
        paymentType: SPLIT_PAYMENT_TYPE["split_payment"],
        splitType:
          splitType != "" ? SPLIT_TYPE[splitType] : SPLIT_TYPE[splitByAmt],
        splitPayment: split_payment,
        singlePayment: null,
      };

      if (
        pendingAmtList.filter((z) => z.paid == false).length &&
        !close_table
      ) {
        let list = [...props.tableOrderBillingList];
        let fIndex = list.findIndex((a) => a.orderId == orderData._id);
        if (fIndex >= 0) {
          list[fIndex] = {
            orderId: orderData._id,
            splitPayment: payment_data,
            discount: discount_data,
          };
        } else {
          list.push({
            orderId: orderData._id,
            splitPayment: payment_data,
            discount: discount_data,
          });
        }
        props.updateTableOrderBillingList(list);
        return;
      } else {
        if (original_split_payment.length == split_payment.length) {
          payment_data = {
            ...payment_data,
            splitPayment: original_split_payment,
            ...discount_data,
          };
        } else {
          if (confirmAlertVisible) {
            payment_data = {
              ...payment_data,
              splitPayment: original_split_payment,
              ...discount_data,
            };
          } else {
            setConfirmAlertVisible(true);
            return;
          }
        }
      }
      if (!close_table) {
        return;
      }
    } else {
      if (pendingAmtList.length == 1) {
        if (pay_method == "" && !close_table) {
          setMsgAlert({
            open: true,
            message: "Select Payment Method",
            msgType: "error",
          });
          return;
        }
        let cashAmt = pendingAmtList[0]["paidAmount"] + tipValue + changeValue;
        payment_data = {
          paymentType: SPLIT_PAYMENT_TYPE["single_payment"],
          splitType: null,
          splitPayment: [],
          singlePayment: {
            paymentMethod: SPLIT_PAYMENT_METHOD[pay_method],
            paidAmount: pendingAmtList[0]["paidAmount"],
            tip: tipValue,
            cashAmount: cashAmt,
            change: changeValue,
            _idMenuItem: [],
            paymentSpecificData: {},
          },
          ...discount_data,
        };
      }
    }

    let data1 = {
      orderStatus: "completed",
      userDetails: {
        ...orderData.userDetails,
        covers: covers_val ? covers_val : 0,
      },
      ...payment_data,
    };
    setIsLoading(true);
    let datas = JSON.stringify(data1);
    Api.updateOrder(datas, orderData._id).then((response) => {
      if (response.success) {
        setShowPaymentAlert(true);
        props.updateTableOrderBillingList(
          [...props.tableOrderBillingList].filter(
            (a) => a.orderId != orderData._id
          )
        );
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setConfirmAlertVisible(false);
      setIsLoading(false);
    });
  };

  const onRemoveCancel = () => {
    setDiscountSplitRemove("");
    setSelectedMenuItems([]);
  };

  const onRemoveItems = () => {
    if (selectedMenuItems.length) {
      let removed_items = selectedMenuItems.map((x) => x._idMenuItem);
      let cart_list = orderData?.cart.filter(
        (x) => !removed_items.includes(x._idMenuItem)
      );
      let cart_items = cartItems.filter(
        (x) => !removed_items.includes(x._idMenuItem)
      );

      let total_amount = getOrdersTotalPrice(cart_list, menuList);

      let data = {
        cart: cart_list,
        totalAmount: total_amount,
      };

      deleteOrderItem(data, cart_items);
    } else {
      setDiscountSplitRemove("");
    }
  };

  const onSubmitActivePrinter = (val) => {
    props.updateActivePrinter(val);
    setActivePrintModalVisible(false);
    printReceiptData(true, val);
  };

  const onCancelActivePrinter = () => {
    setActivePrintModalVisible(false);
    setPrintReceipt(false);
  };

  const printReceiptData = async (val, printer) => {
    setPrintReceipt(val);
    if (val) {
      if (printer == null) {
        setActivePrintModalVisible(true);
        return;
      }

      const data = await render(
        <Printer type="epson">
          <Text>Hello World</Text>
        </Printer>
      );
      if (printerRef.current?.readyState === WebSocket.OPEN) {
        console.log("printer is connected");
        printerRef.current.send(data);
        // printerRef.current.json({ type: "ping" });
        // printerRef.current.close();
      } else {
        setPrinterConnectLoading(true);
        if (printerRef.current) {
          printerRef.current.close();
        }
        console.log("printer not connected");
        let printerIPAddress = printer.IPAddress;
        let printerPort = printer.portNumber;
        console.log("printerIPAddress", printerIPAddress);
        console.log("printerPort", printerPort);
        const WS_API = `ws://${printerIPAddress}:${printerPort}`;
        printerRef.current = new Sockette(WS_API, {
          timeout: 10000,
          maxAttempts: 1,
          onopen: (e) => {
            setMsgAlert({
              open: true,
              message: "Printer Connected",
              msgType: "success",
            });
            setPrinterConnectLoading(false);
            console.log("open", e);
            printerRef.current = e.target;
            printerRef.current.send(data);
            // printerRef.current.json({ type: "ping" });
            // printerRef.current.close();
          },
          onmessage: (e) => console.log("Received:", e),
          onreconnect: (e) => {
            setPrintReceipt(true);
            setPrinterConnectLoading(true);
            console.log("Reconnecting...", e);
          },
          onmaximum: (e) => {
            setPrintReceipt(false);
            setPrinterConnectLoading(false);
            console.log("Stop Attempting!", e);
          },
          onclose: (e) => {
            setPrintReceipt(false);
            setPrinterConnectLoading(false);
            console.log("Closed!", e);
          },
          onerror: (e) => {
            setPrintReceipt(false);
            setPrinterConnectLoading(false);
            console.log("Error:", e);
            printerRef.current.close();
            setMsgAlert({
              open: true,
              message: "Printer Not Connected",
              msgType: "error",
            });
          },
        });
      }
    } else {
      if (printerRef.current) {
        printerRef.current.close();
      }
    }
  };

  const onUpdateCalcValue = (val) => {
    if (discountSplitRemove == "split") {
      val = parseInt(val);
    }
    val = val ? val : 0;
    setPendingAmtBtnDisable(false);
    if (discountSplitRemove == "") {
      if (splitType == "") {
        let pending_amt_list = pendingAmtList.filter((z) => z.paid == false);
        let paid_amt_list = pendingAmtList.filter((z) => z.paid == true);
        if (pending_amt_list.length) {
          let amt = 0;
          pending_amt_list.map((z) => {
            amt = parseFloat(amt) + parseFloat(z.paidAmount);
          });
          if (Number(val) <= amt) {
            let list = [];
            let default_data = {
              paymentMethod: null,
              tip: 0,
              change: 0,
              paymentSpecificData: {},
              _idMenuItem: [],
              paid: false,
              splitItem: false,
            };
            if (Number(val) > 0) {
              let data1 = {
                ...default_data,
                _id: 1,
                paidAmount: Number(val),
                cashAmount: Number(val),
              };
              list.push(data1);
            }
            list = [...list, ...paid_amt_list];
            let amount = amt - Number(val);
            if (amount > 0) {
              let data2 = {
                ...default_data,
                _id: 2,
                paidAmount: amount,
                cashAmount: amount,
              };
              list.push(data2);
            }
            setPendingAmtList(list);
            if (pendingAmtList.length > 1) {
              setPendingAmtBtnDisable(true);
            }
            setSelectedPendingAmt(list[0]);
            setCalcValue(val);
          } else {
            setMsgAlert({
              open: true,
              message: "Amount must be less than pending amount",
              msgType: "error",
            });
          }
        }
        return;
      }
    }
    setCalcValue(val);
  };

  const DiningNavbarView = () => {
    let table_name = "";
    props.floorTableList.map((x) => {
      let index = x.tableDetails.findIndex((y) => y._id == tableId);
      if (index >= 0) {
        table_name = x.tableDetails[index]?.name;
      }
    });
    return (
      <Box className={styles.navbar} sx={{ height: DINING_DETAIL_BTN_HEIGHT }}>
        <DiningNavbar
          restaurantId={props.restaurantId}
          themeMode={props.themeMode}
          center={
            <Typography
              sx={{
                fontFamily: "InterBold",
                fontSize: 30,
                color: "#FFF",
              }}
            >
              Table {table_name}
            </Typography>
          }
          left={
            <Box
              px={3}
              onClick={() => navigate("/dining/details/" + tableId)}
              sx={{
                cursor: "pointer",
                lineHeight: "12px",
                path: {
                  stroke: theme_mode.iconBg,
                },
              }}
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: tabBg[false],
                height: DINING_DETAIL_BTN_HEIGHT,
              }}
            >
              <BackBtnIcon
                height={IS_LARGE_SCREEN ? 45 : 25}
                width={IS_LARGE_SCREEN ? 45 : 25}
              />
              <Typography
                sx={{
                  fontFamily: "InterBold",
                  fontSize: IS_LARGE_SCREEN ? 21 : 16,
                  color: "#FFF",
                  ml: 1,
                }}
              >
                BACK
              </Typography>
            </Box>
          }
        />
      </Box>
    );
  };

  if (isLoading && !confirmAlertVisible) {
    return (
      <Container
        page={"orderSummary"}
        showSidebar={false}
        showToolbar={false}
        showFooter={false}
        p={0}
      >
        <Loader height={{ height: window.innerHeight }} />
      </Container>
    );
  }

  let isAdmin = props.userRole == RESTAURANT_ADMIN_ROLE_ID;

  return (
    <Container
      page={"orderSummary"}
      showSidebar={false}
      showToolbar={false}
      showFooter={false}
      showNavbar={false}
      p={0}
    >
      <Grid container item xs={12}>
        <Grid
          item
          xs={12}
          md={12}
          mb={"5px"}
          pl={0}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <DiningNavbarView />
        </Grid>
        <Grid item xs={12} md={3.5}>
          <Grid sx={{ height: LEFT_CONTENT_HEIGHT }} item>
            <Box className={styles.headerView}>
              <Box
                onClick={() =>
                  isPendingPaymentCompleted ? null : onSelectTab("discount")
                }
                className={styles.billingActionsButton}
                style={{
                  width:
                    props.userRole == RESTAURANT_USER_ROLE_ID ? "49.6%" : "33%",
                  height: HEADER_BTN_HEIGHT,
                  backgroundColor: tabBg[discountSplitRemove == "discount"],
                  cursor: isPendingPaymentCompleted ? "default" : "cursor",
                }}
              >
                <Typography
                  className={styles.billingActionsButtonText}
                  style={{
                    color: tabText[discountSplitRemove == "discount"],
                    opacity: isPendingPaymentCompleted ? 0.4 : 1,
                  }}
                >
                  Discount
                </Typography>
              </Box>
              <Box
                onClick={() =>
                  isPendingPaymentCompleted ? null : onSelectTab("split")
                }
                className={styles.billingActionsButton}
                style={{
                  width:
                    props.userRole == RESTAURANT_USER_ROLE_ID ? "49.6%" : "33%",
                  height: HEADER_BTN_HEIGHT,
                  backgroundColor: tabBg[discountSplitRemove == "split"],
                  cursor: isPendingPaymentCompleted ? "default" : "cursor",
                }}
              >
                <Typography
                  className={styles.billingActionsButtonText}
                  style={{
                    color: tabText[discountSplitRemove == "split"],
                    opacity: isPendingPaymentCompleted ? 0.4 : 1,
                  }}
                >
                  Split
                </Typography>
              </Box>
              {props.userRole == RESTAURANT_USER_ROLE_ID ? null : (
                <Box
                  onClick={() => onSelectTab("remove")}
                  className={styles.billingActionsButton}
                  style={{
                    width: "33%",
                    height: HEADER_BTN_HEIGHT,
                    backgroundColor: tabBg[discountSplitRemove == "remove"],
                  }}
                >
                  <Typography
                    className={styles.billingActionsButtonText}
                    style={{ color: tabText[discountSplitRemove == "remove"] }}
                  >
                    Remove
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              px={1}
              pt={2}
              mt={"5px"}
              className={styles.itemListView}
              style={{
                height:
                  LEFT_CONTENT_HEIGHT -
                  HEADER_BTN_HEIGHT -
                  (discountSplitRemove == "remove" ? BTN_HEIGHT : 0),
                backgroundColor: theme_mode.card2,
                overflow: "auto",
              }}
            >
              {cartItems
                .map((x) => x?._idRestaurantCourse)
                .filter((x, i, a) => a.indexOf(x) == i)
                .map((y1, i1) => {
                  let courseData = null;
                  let index = courseList.findIndex((c) => c._id == y1);
                  if (index >= 0) {
                    courseData = courseList[index];
                    let cart_items = cartItems.filter(
                      (z) => z._idRestaurantCourse == courseData._id
                    );
                    if (cart_items.length) {
                      if (cart_items[0]?.restaurantCourseName) {
                        courseData.name = cart_items[0]?.restaurantCourseName;
                      }
                    }
                    return (
                      <Box key={i1}>
                        <Box
                          className={styles.courseHead}
                          style={{
                            backgroundColor: theme_mode.orderSummaryCourseTitle,
                          }}
                        >
                          <Typography
                            className={styles.courseHeadTxt}
                            style={{ color: theme_mode.text }}
                          >
                            {courseData.name}
                          </Typography>
                        </Box>

                        {cart_items.map((item, index) => {
                          let discount = null;
                          let disabled = false;
                          let clickable = false;

                          if (
                            discountSplitRemove == "discount" &&
                            allOrSelectItem == selectItem
                          ) {
                            clickable = true;
                          }

                          if (
                            discountSplitRemove == "split" &&
                            allOrSelectItem == splitByItem
                          ) {
                            clickable = true;
                          }

                          if (discountSplitRemove == "remove") {
                            clickable = true;
                          }

                          let list = [...discountItems];
                          if (
                            discountSplitRemove == "discount" &&
                            selectedDiscountItems.length
                          ) {
                            list = [...selectedDiscountItems];
                          }
                          let findIndex = list.findIndex(
                            (y) => y._idMenuItem == item._id
                          );
                          if (findIndex >= 0) {
                            if (list[findIndex]?.value) {
                              discount = list[findIndex];
                            }
                          }
                          if (
                            splitItems.length &&
                            discountSplitRemove == "split"
                          ) {
                            disabled =
                              splitItems
                                .reduce(function (arr, e) {
                                  return arr.concat(e);
                                })
                                .findIndex((y) => y == item._idMenuItem) >= 0;
                          }

                          return (
                            <OrderSummaryCartItem
                              key={index}
                              item={item}
                              themeMode={props.themeMode}
                              disabled={disabled}
                              clickable={clickable}
                              currency={CURRENCY_SYMBOL[currencyVal]}
                              selected={
                                selectedMenuItems
                                  .map((x) => x._idMenuItem)
                                  .includes(item._id) &&
                                ((allOrSelectItem != allItems &&
                                  discountSplitRemove == "discount") ||
                                  (allOrSelectItem == splitByItem &&
                                    discountSplitRemove == "split") ||
                                  discountSplitRemove == "remove")
                              }
                              discount={discount}
                              onSelectMenu={onSelectMenu}
                              showBorderBottom={
                                !(cart_items.length == index + 1)
                              }
                            />
                          );
                        })}
                      </Box>
                    );
                  } else {
                    return null;
                  }
                })}
            </Box>

            {discountSplitRemove == "remove" ? (
              <Box
                style={{ height: BTN_HEIGHT }}
                className={styles.cancelRemoveBtnView}
              >
                <Box
                  onClick={() => onRemoveCancel()}
                  className={styles.btnView}
                  style={{
                    backgroundColor: theme_mode.redBg,
                    height: BTN_HEIGHT,
                  }}
                >
                  <Typography
                    color={theme_mode.lightText}
                    className={styles.btnText}
                  >
                    Cancel
                  </Typography>
                </Box>
                <Box
                  onClick={() => onRemoveItems()}
                  className={styles.btnView}
                  style={{
                    backgroundColor: theme_mode.greenBg,
                    height: BTN_HEIGHT,
                  }}
                >
                  <Typography
                    color={theme_mode.lightText}
                    className={styles.btnText}
                  >
                    Remove
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={8.5}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            mb={"5px"}
            pl={`${GAP_VALUE}px`}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <DiningNavbarView />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={"center"}
          >
            <Grid
              item
              xs={12}
              md={6}
              pl={`${GAP_VALUE}px`}
              id={"orderSummaryPayCalcGrid"}
              sx={{
                height: CONTENT_HEIGHT,
                width: payCalcGridWidth,
                overflowY: "auto",
              }}
            >
              <Grid item xs={12} id={"orderSummaryPayGrid"}>
                <Grid item xs={12}>
                  <Box
                    pb={1.5}
                    mb={`${GAP_VALUE}px`}
                    sx={{
                      backgroundColor: theme_mode.card5,
                    }}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      py={IS_LARGE_SCREEN ? 2 : 1.5}
                      px={2}
                      sx={{
                        backgroundColor: "#346d3a",
                      }}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <FileIcon height={IS_LARGE_SCREEN ? 35 : 25} />
                        <Typography
                          className={styles.totalPrice}
                          sx={{
                            ml: 1,
                            color: theme_mode.lightText,
                          }}
                        >
                          Total
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography
                          className={styles.totalPrice}
                          sx={{
                            mr: 1,
                            color: theme_mode.lightText,
                          }}
                        >
                          {CURRENCY_SYMBOL[currencyVal]}
                          {totalPrice}
                        </Typography>
                        <UpIcon height={ICON_SIZE_2} width={ICON_SIZE_2} />
                      </Box>
                    </Box>

                    <Box mt={2} px={2}>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          className={styles.totalAmountInner}
                          sx={{
                            color: theme_mode.lightText,
                          }}
                        >
                          Sub Total
                        </Typography>
                        <Typography
                          className={styles.totalAmountInner}
                          sx={{
                            color: theme_mode.lightText,
                          }}
                        >
                          {CURRENCY_SYMBOL[currencyVal]}
                          {subTotal}
                        </Typography>
                      </Box>
                      {discountAmt > 0 ? (
                        <Box display={"flex"} justifyContent={"space-between"}>
                          <Typography
                            className={styles.totalAmountInner}
                            sx={{
                              color: theme_mode.lightText,
                            }}
                          >
                            Discount
                          </Typography>
                          <Typography
                            className={styles.totalAmountInner}
                            sx={{
                              color: theme_mode.lightText,
                            }}
                          >
                            -{CURRENCY_SYMBOL[currencyVal]}
                            {discountAmt}
                          </Typography>
                        </Box>
                      ) : null}
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          className={styles.totalAmountInner}
                          sx={{
                            color: theme_mode.lightText,
                          }}
                        >
                          Tax
                        </Typography>
                        <Typography
                          className={styles.totalAmountInner}
                          sx={{
                            color: theme_mode.lightText,
                          }}
                        >
                          {CURRENCY_SYMBOL[currencyVal]}
                          {taxVal}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    mb={`${GAP_VALUE}px`}
                    sx={{
                      backgroundColor: theme_mode.card5,
                      minHeight: HEADER_BTN_HEIGHT + 90,
                    }}
                  >
                    <Box
                      px={2}
                      display={"flex"}
                      justifyContent={"space-between"}
                      sx={{
                        backgroundColor: !isPendingPaymentCompleted
                          ? "#DA2222"
                          : "#3C702F",
                        height: HEADER_BTN_HEIGHT - 10,
                      }}
                    >
                      {!isPendingPaymentCompleted ? (
                        <>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              className={styles.totalAmountHead}
                              style={{
                                color: theme_mode.lightText,
                              }}
                            >
                              Pending Amount
                            </Typography>
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              className={styles.totalAmountHead}
                              sx={{
                                mr: 1,
                                color: theme_mode.lightText,
                              }}
                            >
                              {CURRENCY_SYMBOL[currencyVal]}
                              {pendingAmtList
                                .filter((x) => x.paid == false)
                                .reduce(
                                  (partialSum, { paidAmount }) =>
                                    partialSum + paidAmount,
                                  0
                                )
                                .toFixed(2)}
                            </Typography>
                            <DownIcon
                              height={ICON_SIZE_2}
                              width={ICON_SIZE_2}
                            />
                          </Box>
                        </>
                      ) : (
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={"100%"}
                        >
                          <Typography
                            className={styles.totalAmountHead}
                            style={{
                              color: theme_mode.lightText,
                            }}
                          >
                            BILL PAID
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box px={2} pb={1.5} pt={1} className={styles.paymentList}>
                      {pendingAmtList
                        .filter((x) => x?.paid == false)
                        .map((x, i) => {
                          let selected = selectedPendingAmt?._id == x._id;
                          return (
                            <Box key={i}>
                              <Box
                                mb={0.5}
                                mr={1}
                                className={styles.extraPaymentItem}
                                sx={{
                                  cursor: pendingAmtBtnDisable
                                    ? "default"
                                    : "pointer",
                                  backgroundColor: selected
                                    ? theme_mode.theme
                                    : theme_mode.darkLightBg,
                                  // border: selected
                                  //   ? "1px solid #FFF"
                                  //   : "1px solid transparent",
                                }}
                                onClick={() =>
                                  pendingAmtBtnDisable
                                    ? null
                                    : onSelectPendingAmtList(x)
                                }
                              >
                                {/*{x?.paid ? (*/}
                                {/*  <Box className={styles.paidIcon}>*/}
                                {/*    <PaidIcon*/}
                                {/*      width={IS_LARGE_SCREEN ? 100 : 80}*/}
                                {/*      height={IS_LARGE_SCREEN ? 100 : 80}*/}
                                {/*    />*/}
                                {/*  </Box>*/}
                                {/*) : null}*/}
                                <Typography
                                  className={styles.extraPaymentItemPrice}
                                  sx={{
                                    color: theme_mode.lightText,
                                  }}
                                >
                                  {Number(x.paidAmount).toFixed(2)}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box
                  // py={1.5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor:
                      props.themeMode == "light" ? "#FFF" : theme_mode.card5,
                  }}
                >
                  <Calculator
                    // width={payCalcGridWidth / 3.6}
                    width={
                      (payCalcGridWidth -
                        CALC_PADDING * 2 * 2 -
                        CALC_PADDING * 2) /
                      3
                    }
                    height={
                      (CONTENT_HEIGHT -
                        payGridHeight -
                        4 * CALC_PADDING -
                        10 -
                        CALC_PADDING * 3) /
                      4
                    }
                    // height={
                    //   ((payCalcGridWidth -
                    //     CALC_PADDING * 2 * 2 -
                    //     CALC_PADDING * 2) /
                    //     3) *
                    //   0.8
                    // }
                    disabled={splitType != "" && discountSplitRemove == ""}
                    textStyle={{
                      fontSize: IS_LARGE_SCREEN ? 25 : 20,
                      opacity:
                        splitType != "" && discountSplitRemove == "" ? 0.3 : 1,
                    }}
                    iconSize={IS_LARGE_SCREEN ? 34 : 26}
                    currentVal={calcValue}
                    emptyValue={true}
                    onUpdate={onUpdateCalcValue}
                    mainViewStyle={{
                      margin: `${CALC_PADDING * 2}px`,
                      width: payCalcGridWidth,
                      // width: payCalcGridWidth - 10,
                      // height:
                      //   ((payCalcGridWidth -
                      //     CALC_PADDING * 2 * 2 -
                      //     CALC_PADDING * 2) /
                      //     3) *
                      //     4 *
                      //     0.8 +
                      //   3 * CALC_PADDING,
                      height:
                        CONTENT_HEIGHT - payGridHeight - 4 * CALC_PADDING - 10,
                      justifyContent: "space-between",
                    }}
                    calcStyle={{
                      marginLeft: 0,
                      marginBottom: 0,
                      cursor:
                        splitType != "" && discountSplitRemove == ""
                          ? "default"
                          : "pointer",
                    }}
                    iconStyle={{
                      path: {
                        fill: "#000",
                      },
                    }}
                    themeMode={props.themeMode}
                  />
                </Box>
              </Grid>
            </Grid>
            {discountSplitRemove == "discount" ||
            discountSplitRemove == "split" ? (
              <Grid
                item
                xs={12}
                md={6}
                pl={{ xs: 0, md: `${GAP_VALUE}px` }}
                style={{ height: CONTENT_HEIGHT, width: "100%" }}
              >
                <OrderSummaryDiscountSplit
                  calcValue={calcValue}
                  discountSplit={discountSplitRemove}
                  coversVal={coversOriginalVal}
                  themeMode={props.themeMode}
                  drawerOpen={props.drawerOpen}
                  allOrSelectItem={allOrSelectItem}
                  currency={CURRENCY_SYMBOL[currencyVal]}
                  setCalcValue={setCalcValue}
                  onChangeDiscount={onChangeDiscountValue}
                  setAllOrSelectItem={onSetAllOrSelectItem}
                  onCancel={onCancelDiscountSplit}
                  onApplyDisc={onApplyDiscount}
                  onApplySplit={onApplySplit}
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    height: CONTENT_HEIGHT,
                    backgroundColor: theme_mode.card,
                    position: "relative",
                  }}
                  className={styles.itemListView}
                >
                  <Grid
                    item
                    xs={12}
                    px={props.themeMode == "light" ? 2 : `${GAP_VALUE}px`}
                    pt={props.themeMode == "light" ? 2 : 0}
                    pb={2}
                    style={{
                      height:
                        CONTENT_HEIGHT -
                        (orderData
                          ? (pendingAmtList.length == 1 ? 1 : 2) * BTN_HEIGHT
                          : 0),
                      overflow: "auto",
                    }}
                  >
                    <Grid item xs={12} id={"paymentMethodOptionGrid"}>
                      <Grid item>
                        <Box>
                          {PAYMENT_METHOD.map((x, i) => {
                            return (
                              <PaymentMethod
                                key={i}
                                tab={"method"}
                                themeMode={theme_mode}
                                width={"100%"}
                                type={x.name}
                                icon={x.icon}
                                iconColor={
                                  props.themeMode == "dark"
                                    ? theme_mode.iconBg
                                    : x.type == paymentMethod
                                    ? theme_mode.iconBg
                                    : "#FFFFFF"
                                }
                                active={x.type == paymentMethod}
                                onClick={() => onSelectPaymentMethod(x.type)}
                              />
                            );
                          })}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box className={styles.optionsListView}>
                          {OPTIONS_LIST.map((x, i) => {
                            return (
                              <PaymentMethod
                                key={i}
                                tab={"option"}
                                themeMode={theme_mode}
                                // width={"49.5%"}
                                width={`calc(50% - ${GAP_VALUE / 2}px)`}
                                type={x.name}
                                icon={x.icon}
                                iconColor={
                                  props.themeMode == "dark"
                                    ? theme_mode.iconBg
                                    : x.type == paymentOption
                                    ? theme_mode.iconBg
                                    : "#FFFFFF"
                                }
                                active={x.type == paymentOption}
                                onClick={() => onSelectPaymentOptions(x.type)}
                              />
                            );
                          })}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={styles.tipChangePrintView}
                      style={{
                        justifyContent:
                          pendingAmtList.length == 1
                            ? "center"
                            : "space-between",
                        minHeight:
                          CONTENT_HEIGHT -
                          (orderData
                            ? (pendingAmtList.length == 1 ? 1 : 2) * BTN_HEIGHT
                            : 0) -
                          paymentMethodOptionGridHeight -
                          20,
                      }}
                    >
                      <Grid item xs={12}>
                        <Grid item>
                          <Box
                            px={2}
                            py={1}
                            mb={`${GAP_VALUE}px`}
                            className={styles.tipChangeView}
                            sx={{
                              backgroundColor: theme_mode.card5,
                            }}
                          >
                            <Box
                              className={styles.paymentSubView}
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Box display={"flex"} alignItems={"center"}>
                                <AddTip height={ICON_SIZE} />
                                <Typography
                                  className={styles.totalAmountHead}
                                  style={{
                                    color: theme_mode.lightText,
                                    marginLeft: 10,
                                  }}
                                >
                                  Add Tip
                                </Typography>
                              </Box>
                              <DownIcon
                                height={ICON_SIZE_2}
                                width={ICON_SIZE_2}
                              />
                            </Box>

                            <Box>
                              <Typography
                                className={styles.totalAmountInner}
                                sx={{
                                  color: theme_mode.lightText,
                                }}
                              >
                                Add the tip vou've received here
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item>
                          <Box
                            className={styles.tipChangeView}
                            px={2}
                            py={1.5}
                            mb={`${GAP_VALUE}px`}
                            sx={{
                              backgroundColor: theme_mode.card5,
                            }}
                          >
                            <Box
                              className={styles.paymentSubView}
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Box display={"flex"} alignItems={"center"}>
                                <Change height={ICON_SIZE} />
                                <Typography
                                  className={styles.totalAmountHead}
                                  style={{
                                    color: theme_mode.lightText,
                                    marginLeft: 10,
                                  }}
                                >
                                  Change
                                </Typography>
                              </Box>

                              <Typography
                                className={styles.totalAmountHead}
                                sx={{
                                  color: theme_mode.lightText,
                                }}
                              >
                                {CURRENCY_SYMBOL[currencyVal]}
                                {changeValue.toFixed(2)}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        {pendingAmtList.length == 1 ? (
                          <Grid item>
                            <Box
                              className={styles.tipChangeView}
                              px={2}
                              py={1.5}
                              mb={`${GAP_VALUE}px`}
                              sx={{
                                backgroundColor: theme_mode.card5,
                              }}
                            >
                              <Box
                                className={styles.printReceiptView}
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <Box display={"flex"} alignItems={"center"}>
                                  <PrintReceipt height={ICON_SIZE} />
                                  <Typography
                                    className={styles.totalAmountHead}
                                    style={{
                                      color: theme_mode.lightText,
                                      marginLeft: 10,
                                    }}
                                  >
                                    Print receipt
                                  </Typography>
                                </Box>
                                {printerConnectLoading ? (
                                  <CircularProgress
                                    color={"warning"}
                                    size={20}
                                  />
                                ) : (
                                  <MuiSwitch
                                    checked={printReceipt}
                                    onChange={(e) =>
                                      printReceiptData(
                                        !printReceipt,
                                        props.activePrinter
                                      )
                                    }
                                  />
                                )}

                                {/*<Typography*/}
                                {/*  className={styles.totalAmountHead}*/}
                                {/*  sx={{*/}
                                {/*    color: theme_mode.lightText,*/}
                                {/*  }}*/}
                                {/*>*/}
                                {/*  $00.00*/}
                                {/*</Typography>*/}
                              </Box>
                            </Box>
                          </Grid>
                        ) : null}
                      </Grid>

                      <Grid className={styles.paidMainView}>
                        {pendingAmtList
                          .filter((x) => x?.paid == true)
                          .map((x, i) => {
                            return (
                              <Box
                                key={i}
                                p={1}
                                mr={1}
                                mb={1}
                                className={styles.paidView}
                              >
                                <Typography className={styles.paidMethod}>
                                  {getKeyByValue(
                                    SPLIT_PAYMENT_METHOD,
                                    x.paymentMethod
                                  )}
                                </Typography>
                                <Typography
                                  className={styles.paidValue}
                                  sx={{ color: theme_mode.lightText }}
                                >
                                  {CURRENCY_SYMBOL[currencyVal]}
                                  {Number(x.paidAmount).toFixed(2)}
                                </Typography>
                              </Box>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                  {orderData ? (
                    pendingAmtList.length == 1 ? (
                      <Box
                        px={2}
                        sx={{
                          backgroundColor: theme_mode.greenBg,
                          width: "100%",
                          height: BTN_HEIGHT,
                          cursor: "pointer",
                        }}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        onClick={() => onCreateOrder(paymentMethod, false)}
                      >
                        <Typography
                          className={styles.payBtnText}
                          sx={{
                            color: theme_mode.lightText,
                          }}
                        >
                          Pay - {CURRENCY_SYMBOL[currencyVal]}
                          {selectedPendingAmt
                            ? Number(selectedPendingAmt.paidAmount).toFixed(2)
                            : totalPrice}
                        </Typography>
                      </Box>
                    ) : pendingAmtList.length > 1 ? (
                      <>
                        <Box
                          px={2}
                          sx={{
                            backgroundColor: "#0D4EA8",
                            width: "100%",
                            height: BTN_HEIGHT,
                            cursor:
                              isPendingPaymentCompleted || isAdmin
                                ? "pointer"
                                : "default",
                          }}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Typography
                            className={styles.payBtnText}
                            sx={{
                              opacity:
                                isPendingPaymentCompleted || isAdmin ? 1 : 0.3,
                              color: theme_mode.lightText,
                            }}
                          >
                            PRINT RECEIPT
                          </Typography>
                        </Box>
                        <Box
                          px={2}
                          sx={{
                            backgroundColor: theme_mode.greenBg,
                            width: "100%",
                            height: BTN_HEIGHT,
                            cursor:
                              isPendingPaymentCompleted || isAdmin
                                ? "pointer"
                                : "default",
                          }}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          onClick={() =>
                            isPendingPaymentCompleted || isAdmin
                              ? onCreateOrder(paymentMethod, true)
                              : null
                          }
                        >
                          <Typography
                            className={styles.payBtnText}
                            sx={{
                              opacity:
                                isPendingPaymentCompleted || isAdmin ? 1 : 0.3,
                              color: theme_mode.lightText,
                            }}
                          >
                            CLOSE TABLE
                          </Typography>
                        </Box>
                      </>
                    ) : null
                  ) : null}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
        <OrderSummaryPaymentAlert
          modalVisible={showPaymentAlert}
          msg={"Payment Successful"}
          themeMode={props.themeMode}
          onClick={() => navigate("/dining")}
        />
        <SelectActivePrinterModal
          modalVisible={activePrintModalVisible}
          themeMode={props.themeMode}
          restaurantId={props.restaurantId}
          onSubmit={onSubmitActivePrinter}
          onCancel={onCancelActivePrinter}
        />
        <ConfirmAlert
          confirmDelete={confirmAlertVisible}
          icon={<AlertIcon height={50} width={50} />}
          title={
            "There are still some pending amounts to be paid. Do you want to continue?"
          }
          titleStyle={{ fontFamily: "InterMedium !important" }}
          firstBtn={"No"}
          secondBtn={isLoading ? "Please Wait" : "Yes"}
          btnLoading={isLoading}
          onCloseConfirmDelete={() => setConfirmAlertVisible(false)}
          onConfirmDelete={() => onCreateOrder(paymentMethod, true)}
          themeMode={props.themeMode}
        />
      </Grid>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantMenuList: (data) =>
      dispatch(updateRestaurantMenuList(data)),
    updateRestaurantCourseList: (data) =>
      dispatch(updateRestaurantCourseList(data)),
    updateTableOrderBillingList: (data) =>
      dispatch(updateTableOrderBillingList(data)),
    updateTableOrderList: (data) => dispatch(updateTableOrderList(data)),
    updateActivePrinter: (data) => dispatch(updateActivePrinter(data)),
    updateDrawerOpen: (data) => dispatch(updateDrawerOpen(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
    drawerOpen: state.userData.drawerOpen,
    restaurantMenuList: state.userData.restaurantMenuList,
    restaurantCourseList: state.userData.restaurantCourseList,
    restaurantDetail: state.userData.restaurantDetail,
    tableOrderBillingList: state.userData.tableOrderBillingList,
    tableOrderList: state.userData.tableOrderList,
    activePrinter: state.userData.activePrinter,
    userRole: state.userData.userRole,
    floorTableList: state.userData.floorTableList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dining);
