import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import styles from "./RestaurantSettings.module.css";

import { connect } from "react-redux";
import { updateRestaurantDetail } from "../../redux/actions/userDataActions";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import SettingTextField from "../../components/SettingTextField";
import AlertMsg from "../../components/AlertMsg";
import SettingSelectField from "../../components/SettingSelectField";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import { ReactComponent as TimeIcon } from "../../assets/images/time.svg";
import { ReactComponent as PinIcon } from "../../assets/images/pin.svg";
import { ReactComponent as DollarIcon } from "../../assets/images/dollar.svg";

import { THEME_MODE } from "../../constants/Theme";
import {
  CONTAINER_HEIGHT,
  CURRENCY_LIST,
  FOOTER_XPADDING,
} from "../../constants";

let currency_list = CURRENCY_LIST;

const OrderTimeSettings = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles1 = styles2(theme_mode);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [staffPinLen, setStaffPinLen] = useState("");
  const [currencyVal, setCurrencyVal] = useState("");
  const [serviceChg, setServiceChg] = useState(0);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  const [error, setError] = useState(null);
  const [editable, setEditable] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);

  useEffect(() => {
    setError(null);
    getRestaurantData();
  }, []);

  useEffect(() => {
    if (editable) {
      let data = props.restaurantDetail;

      let currencyIcon = "";
      let currency_data = currency_list.filter((x) => x.value == currencyVal);
      if (currency_data.length) {
        currencyIcon = currency_data[0]["currency"];
      }

      if (
        data.currecnyIcon !== currencyIcon ||
        data.currecny !== currencyVal ||
        data.serviceCharge !== serviceChg
        // || data.staffPinLength !== staffPinLen
      ) {
        setSaveBtnDisabled(false);
      } else {
        setSaveBtnDisabled(true);
      }
    }
  }, [currencyVal, staffPinLen, serviceChg]);

  const getRestaurantData = () => {
    setLoading(true);
    Api.getRestaurantData(props.restaurantId).then((response) => {
      if (response.success) {
        let data = response.data;
        setCurrencyVal(data.currecny);
        setServiceChg(data?.serviceCharge ? data?.serviceCharge : 0);
        props.updateRestaurantDetail(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onSaveGeneralSettings = () => {
    let err = null;

    if (staffPinLen == "" || staffPinLen == null) {
      err = { ...err, staffPinLen: "Staff Pin Length Required" };
    } else {
      if (/[^0-9]/g.test(staffPinLen)) {
        err = { ...err, staffPinLen: "Invalid Staff Pin Length" };
      }
    }

    let currency_data = currency_list.filter((x) => x.value == currencyVal);
    if (currency_data.length == 0) {
      err = { ...err, currency: "Currency Required" };
    }

    if (!serviceChg) {
      err = { ...err, serviceCharge: "Service Charge Required" };
    } else {
      if (/[^0-9]/g.test(serviceChg.toString())) {
        err = { ...err, serviceCharge: "Invalid Service Charge" };
      }
    }

    setError(err);
    if (err != null) {
      return;
    }

    let data = {
      currecny: currencyVal,
      currecnyIcon: currency_data[0]["currency"],
      serviceCharge: serviceChg,
      // staffPinLength: staffPinLen,
    };
    let datas = JSON.stringify(data);

    setLoading(true);
    Api.updateRestaurant(datas, props.restaurantId).then((response) => {
      if (response.success) {
        props.updateRestaurantDetail({
          ...props.restaurantDetail,
          currecny: data.currecny,
          currecnyIcon: data.currecnyIcon,
          serviceCharge: data.serviceCharge,
          // staffPinLength: data.staffPinLength,
        });
        setEditable(false);
        setSaveBtnDisabled(true);
        setMsgAlert({ open: true, message: response.msg, msgType: "success" });
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onEditSettings = () => {
    setEditable(true);
  };

  const handlerOnChange = (type, val) => {
    if (type === "serviceCharge") {
      setServiceChg(val.replace(/[^0-9]/g, ""));
    }
  };

  return (
    <Container
      page={"settings"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0.5}
    >
      {loading ? (
        <Loader />
      ) : (
        <Grid
          p={1.5}
          item
          xs={12}
          style={{
            backgroundColor: theme_mode.card3,
            borderRadius: 15,
            minHeight: CONTAINER_HEIGHT - 70,
          }}
        >
          <Grid container direction={"column"} item md={4.5} xs={12} mt={1}>
            <Box
              mb={2}
              onClick={() => navigate("/settings")}
              sx={{ cursor: "pointer" }}
            >
              <BackBtnIcon width={33} height={33} />
            </Box>

            <Grid item xs={12} mt={2}>
              <Box>
                <SettingTextField
                  page={"settings"}
                  editable={editable}
                  value={staffPinLen}
                  setValue={(val) => setStaffPinLen(val.replace(/[^0-9]/g, ""))}
                  placeholder={""}
                  label={"Staff Pin Length"}
                  labelStyle={{
                    ...styles1.textInputLabel,
                    fontSize: "14.5px",
                    fontFamily: "InterSemiBold !important",
                  }}
                  required={true}
                  variant={"filled"}
                  leftView={<PinIcon width={20} height={20} />}
                  error={error?.staffPinLen}
                />
              </Box>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Box>
                <SettingSelectField
                  page={"settings"}
                  editable={editable}
                  value={currencyVal}
                  setValue={setCurrencyVal}
                  placeholder={""}
                  label={"Currency"}
                  labelStyle={{
                    ...styles1.textInputLabel,
                    fontSize: "14.5px",
                    fontFamily: "InterSemiBold !important",
                  }}
                  required={true}
                  variant={"filled"}
                  menuList={currency_list}
                  leftView={<DollarIcon width={20} height={20} />}
                  error={error?.currency}
                />
              </Box>
            </Grid>
            <Grid item xs={12} mt={2}>
              <SettingTextField
                page={"settings"}
                editable={editable}
                value={serviceChg}
                setValue={(val) => handlerOnChange("serviceCharge", val)}
                placeholder={""}
                label={"Service Charge(%)"}
                labelStyle={{
                  ...styles1.textInputLabel,
                  fontSize: "14.5px",
                  fontFamily: "InterSemiBold !important",
                }}
                required={true}
                variant={"filled"}
                leftView={<DollarIcon width={20} height={20} />}
                error={error?.serviceCharge}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <Box className={styles.btnMainView} mt={2}>
                <Button
                  type="button"
                  className={styles.editBtn}
                  sx={styles1.editBtn}
                  onClick={onEditSettings}
                >
                  Edit
                </Button>
                <Button
                  type="button"
                  disabled={saveBtnDisabled}
                  className={styles.saveBtn}
                  sx={{
                    ...styles1.saveBtn,
                    backgroundColor: saveBtnDisabled
                      ? theme_mode.btnDisabledBg
                      : theme_mode.btnBg4,
                  }}
                  onClick={onSaveGeneralSettings}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantDetail: state.userData.restaurantDetail,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTimeSettings);

const styles2 = (Theme) => ({
  saveBtn: {
    backgroundColor: Theme.btnBg4,
    color: Theme.btnText,
    ml: 1,
    "&:hover": {
      backgroundColor: Theme.btnBg4 + " !important",
    },
  },
  editBtn: {
    backgroundColor: Theme.btnBg5,
    border: "2px solid " + Theme.btnBg5Border,
    color: Theme.text,
    mr: 1,
    "&:hover": {
      backgroundColor: Theme.btnBg5 + " !important",
    },
  },
});
